.client-list-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  margin-top: 100px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px var(--shadow-color);
      overflow-x: auto; /* Enable horizontal scrolling */

}

h2 {
  text-align: right;
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 24px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin-bottom: 15px;
}

.date-dropdown {
  width: calc(100% - 50px);
  max-width: 300px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  margin-right: 10px;
}
.search-bar {
  width: calc(100% - 20px);
  max-width: 300px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  margin-right: 10px;
}

.reload-button {
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reload-icon {
  padding: 2px;
  width: 30px;
  height: 30px;
  filter: invert(0); /* Ensures icon is visible in both light and dark modes */
}

.user-query{
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--card-bg-color);
  width: fit-content;
  padding: 3px 10px;
  margin-bottom: 5px;
}

.user-query:hover {
  background-color: var(--primary-color-hover);
  color: white;
  cursor: pointer;
}
.registration-group {
  margin-top: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--card-bg-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}


.group-header {
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-header:hover {
  background-color: var(--primary-color-hover);
}

.client-table {
  width: 100%;
  border-collapse: collapse;

}

.client-table colgroup col {
  width: auto; /* Ensures uniform columns */
}

.client-table colgroup .col-id {
  width: 10%; /* Adjust as needed */
}

.client-table colgroup .col-name {
  width: 20%; /* Same width for all name columns */
}

.client-table colgroup .col-id-number {
  width: 15%; /* Adjust for ID/teudatZehut */
}

.client-table colgroup .col-phone {
  width: 15%; /* Same width for phone numbers */
}

.client-table colgroup .col-status {
  width: 15%;
}

.client-table colgroup .col-date {
  width: 25%; /* Same width for date column */
}

.client-table thead {
  background-color: var(--table-header-bg-color);
}

.client-table th,
.client-table td {
  padding: 8px 12px;
  text-align: right;
  border: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--text-color);
}

.client-table th {
  font-weight: bold;
  color: var(--text-light-color);
}

.client-table tr:hover {
  background-color: var(--hover-bg-color);
  cursor:pointer;
}

.client-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.client-table tr:hover {
  background-color: var(--primary-color-hover);
}

.open-icon {
  width: 20px;
  height: 20px;
  fill: white;
}

@media (max-width: 600px) {
  .client-list-container {
    padding: 15px;
    margin-top: 140px;
  }

  h2 {
    font-size: 20px;
  }
  .group-header {
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  .client-table{
    overflow-x: auto; /* Enable horizontal scrolling */

  }
  .client-table th,
  .client-table td {
    padding: 6px;
    font-size: 12px;
  }

  .reload-icon {
    width: 24px;
    height: 24px;
  }

  .date-dropdown {
    width: 100%;
  }
}
