.transaction-container {
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 2px 10px var(--shadow-color);
  margin: 20px auto;
  font-family: 'Arial', sans-serif;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid var(--border-color);
  padding: 8px;
  font-size: 0.9em;
  text-align: center;
}

.transaction-table th {
  background-color: var(--panel-bg-color);
  font-weight: bold;
  text-align: center;
}

.transaction-table input[type="text"],
.transaction-table input[type="number"],
.transaction-table select {
  width: 90%;
  padding: 5px;
  font-size: 0.9em;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  appearance: none; /* Removes default browser styles */
}

.transaction-table select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="%233c6eb4" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px; /* Add padding to make space for the dropdown arrow */
}

.transaction-table input[type="checkbox"] {
  margin: auto;
  display: block;
  transform: scale(1.2);
}

.add-item-button {
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 0.9em;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.add-item-button:hover {
  background-color: var(--primary-color-hover);
}

.amount-summary {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount-summary label {
  display: inline-block;
  width: 45%;
  font-size: 0.9em;
  text-align: center;
}

.amount-summary input[type="text"],
.amount-summary input[type="number"] {
  width: 50%;
  padding: 5px;
  font-size: 0.9em;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  text-align: right;
}

.transaction-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.transaction-actions button {
  padding: 10px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  width: 48%;
}

.cancel-button {
  background-color: var(--danger-bg-color);
}

.cancel-button:hover {
  background-color: var(--danger-bg-color-hover);
}

.generate-button {
  background-color: var(--success-bg-color);
}

.generate-button:hover {
  background-color: var(--success-bg-color-hover);
}
