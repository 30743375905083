@import './variables.css';

/* Background overlay for the popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Popup container */
.client-management-popup {
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  padding: 20px;
  border-radius: 15px;
  max-width: 1100px; 
  width: 100%;
  box-shadow: 0 5px 15px var(--shadow-color);
  position: relative;
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
  max-height: 90vh; 
  overflow-y: auto;
}

/* Popup header */
.popup-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

/* Dropdown styling */
.dropdowns {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.dropdowns label {
  flex: 1;
  font-size: 14px;
  
  color: var(--text-dark-color);
}

.dropdowns select {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  font-size: 1rem;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
}

/* Client details */
.client-detail {
  background-color: var(--light-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
}
.client-detail label{
  font-weight: bold;
  margin: 5px;
}

.details-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}


.details-row p {
  flex: 1;
  font-size: 1rem;
  color: var(--text-dark-color);
}
.details-col{
  display: flex;
  flex-direction: column;
  
  gap: 10px;
  width: 50%;
}


.client-detail input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  font-size: 1rem;
}

.client-detail-gen {
  background-color: var(--light-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

/* File input styling */
.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 10px 0;
  color: var(--text-dark-color);
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: background-color 0.3s, transform 0.3s;
  flex: 1;
  margin: 5px;
  border: 1px solid var(--border-color);
  text-align: center;
}

.file-input-button {
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-input-button:hover {
  background-color: var(--primary-color-hover);
}

.file-input-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

/* File links */
.file-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.show-button {
  padding: 10px;
  font-size: 14px;
  color: var(--text-dark-color);
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: background-color 0.3s, transform 0.3s;
  flex: 1;
  margin: 5px;
}

.show-button.disabled {
  background-color: var(--disabled-bg-color);
  opacity: 60%;
  cursor: not-allowed;
}
.show-button.disabled-warning {
  background-color: var(--danger-bg-color);
  opacity: 60%;
  cursor: not-allowed;
}

.show-button:not(.disabled):hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-2px);
}
/* Bullet counter */
.bullet-counter {
  display: flex;
  align-items: center;
}

.bullet-counter button {
  padding: 5px 10px;
  font-size: 1.3em;
  background-color: var(--panel-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  cursor: pointer;
  margin: 0 5px;
  color: var(--button-text-color);
}

.bullet-counter input {
  width: 80px;
  text-align: center;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 5px;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
}
/* General buttons */
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.actions button {
  padding: 10px;
  font-size: 14px;
  color: var(--text-dark-color);
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;
}

.approve-button {
  background-color: var(--success-bg-color);
}

.approve-button:hover {
  background-color: var(--success-bg-color-hover);
}

.edit-button {
  background-color: var(--warning-bg-color);
}

.edit-button:hover {
  background-color: var(--warning-bg-color-hover);
}

.delete-button {
  background-color: var(--danger-bg-color);
}

.delete-button:hover {
  background-color: var(--danger-bg-color-hover);
}

.close-button {
  background-color: var(--danger-bg-color);
  position:relative;
  top:-10px;
  right:0;
  border: 2px dashed white;
  z-index: 99999;
}

.close-button:hover {
  background-color: var(--danger-bg-color-hover);
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;

}

.loading-spinner::after {
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 12px solid var(--primary-color);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

.loading-spinner-small{
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
}
.loading-spinner-small::after {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid var(--primary-color);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}


/* Responsive adjustments */
@media (max-width: 600px) {
  .details-row {
    flex-direction: column;
  }
  .details-col {
    width: 100%;
  }

  .file-links {
    flex-direction: column;
  }

  .show-button {
    margin-bottom: 10px;
  }

  .dropdowns {
    flex-direction: column;
    gap: 10px;
  }

  .actions {
    flex-direction: column;
  }
}
