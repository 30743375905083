.smart-input-container {
    position: relative;
    width: 100%;
    text-align: right;
    margin-bottom: 16px;
    padding: 0;
}

.smart-fieldset {
    caret-color: transparent;
    position: relative;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 0;
    transition: border-color 0.3s ease-in-out;
    width: 100%;
    height: 48px;
    right: -2px;
    display: flex;
    align-items: center;
    &:hover {
        border-color: #1976d2;
    }
    &:focus-within {
        border-color: #1976d2;
    }
}

.smart-legend {
    font-size: 14px;
    font-weight: 400;
    padding: 2px 12px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    color: var(--text-dark-color);
    z-index: 2;
    pointer-events: none; /* Make the legend non-clickable */
}

.smart-input-container.active .smart-legend,
.smart-input:focus + .smart-legend,
.smart-select:focus + .smart-legend {
    top: -2px;
    font-size: 14px;
    color: var(--text-dark-color);
    padding: 2px 12px;
    border-radius: 4px;
    background: linear-gradient(to top, var(--card-bg-color) 53%, transparent 20%);
    transition: all 0.3s ease-in-out;
}

.smart-input, .smart-select {
    width: 100%;
    height: 100%;
    padding: 10px 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    outline: none;
    background: var(--card-bg-color);
    color: var(--text-dark-color);
    z-index: 1;
    direction: rtl;
    text-align: right;
    position: relative; /* Ensure the input/select is positioned above the legend */
}

.smart-input-container.active .smart-fieldset {
    border-color: #1976d2;
}

.smart-input-container.error .smart-fieldset  {
    border-color: red;
}
.smart-input-container.error .smart-legend {
    color: red;
}

.helper-text {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    text-align: right;
    text-shadow: 0 0 1px red;
    animation: slow-blink 1s linear 6;
}

@keyframes slow-blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

/* Dark-themed DatePicker Container */
.dark-date-picker-container {
    background-color: var(--card-bg-color);
    color: var(--text-dark-color);
    font-family: "Segoe UI", sans-serif;
    padding: 10px;
    border: 2px solid #1976d2;
    border-radius: 4px;
    width: 100%;
    position: relative;
  }
  
  /* Layout for the Month/Year header */
  .dark-date-picker-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  /* Month/Year <select> */
  .dark-date-picker-select {
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    border: 1px solid #666;
    padding: 4px;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .dark-date-picker-select:hover {
    border-color: var(--tab-bg-color);
  }
  
  /* The day grid */
  .dark-date-picker-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  /* Day name row */
  .dark-date-picker-day-name {
    text-align: center;
    font-weight: bold;
    background-color: var(--card-bg-color);
    padding: 4px 0;
    border-radius: 3px;
    border: 1px solid var(--border-color);
    font-size: 12px;
    caret-color: transparent;
  }
  
  /* Day cells */
  .dark-date-picker-day {
    text-align: center;
    padding: 6px 0;
    background-color: var(--card-bg-color);
    border-radius: 3px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
    font-size: 14px;
    caret-color: transparent;
  }
  
  .dark-date-picker-day:hover {
    background-color: var(--hover-bg-color);
  }
  
  .dark-date-picker-day.selected {
    background-color: #1976d2;
    border-color: #1976d2;
    color: #fff;
  }
  
  /* Days from other months */
  .dark-date-picker-day.not-current {
    color: #777;
  }
  
  /* Display the selected date below the grid */
  .dark-date-selected {
    margin-top: 20px;
    font-size: 14px;
    color: var(--text-dark-color);
    border: 1px solid var(--border-color);
    width: fit-content;
    background-color: #1976d2;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    caret-color: transparent;
  }
  
  /* Error state */
  .dark-date-picker-container.error {
    border: 1px solid red;
  }
  
  
  /* Optional for helper text, if used */
  .helper-text {
    font-size: 12px;
    color: red;
    margin-top: 4px;
  }
  

/* Smart Checkbox Styles */
.smart-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.smart-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: var(--text-dark-color);
}

.smart-checkbox {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.smart-checkbox-container.error .smart-checkbox-label {
    color: red;
}


/* mobile part */
@media screen and (max-width: 768px) {
    .smart-input-container {
        margin-bottom: 20px;

    }
    .smart-fieldset {
        height: 40px;
    }
    .smart-legend {
        font-size: 12px;
        padding: 2px 8px;
    }
    .smart-input, .smart-select {
        padding: 8px 10px;
        font-size: 14px;
    }
    .helper-text {
        font-size: 10px;
    }
    .date-header {
        gap: 4px;
    }
    .month-nav {
        font-size: 14px;
        min-width: 50px;
    }
    .calendar-day {
        padding: 8px;
    }
    .selected-date {
        font-size: 14px;
    }
    .smart-checkbox-label {
        font-size: 14px;
    }
    .smart-checkbox {
        width: 16px;
        height: 16px;
    }
    .calendar-grid {
        grid-template-columns: repeat(6, 1fr); /* Adjust for mobile view */
    }
    
}

