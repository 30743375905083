/* Light mode */
:root {
  --primary-color: #4e84e9;
  --primary-color-hover: #3c6eb4;
  --light-bg-color: #f9f9f9;
  --tab-bg-color: #f1f1f1;
  --card-bg-color: #eeeaea;
  --text-dark-color: #333333;
  --border-color: #dddddd;
  --shadow-color: rgba(0, 0, 0, 0.1);

  /* Added colors */
  --input-bg-color: #ffffff;
  --input-text-color: #333333;
  --disabled-bg-color: #cccccc;
  --disabled-text-color: #999999;
  --success-bg-color: #4CAF50;
  --success-bg-color-hover: #45A049;
  --warning-bg-color: #ff9800;
  --warning-bg-color-hover: #fb8c00;
  --danger-bg-color: #f44336;
  --danger-bg-color-hover: #e53935;
  --muted-bg-color: #9e9e9e;
  --muted-bg-color-hover: #757575;
  --panel-bg-color: #fafafa;
}

/* Dark mode */
.dark-mode {
  --primary-color: #3c6eb4;
  --primary-color-hover: #2a5296;
  --light-bg-color: #1a1a1a;
  --tab-bg-color: #2a2a2a;
  --card-bg-color: #2b2b2b;
  --text-dark-color: #f5f5f5;
  --border-color: #444444;
  --shadow-color: rgba(0, 0, 0, 0.5);

  /* Added colors */
  --input-bg-color: #333333;
  --input-text-color: #f5f5f5;
  --disabled-bg-color: #555555;
  --disabled-text-color: #777777;
  --success-bg-color: #4CAF50;
  --success-bg-color-hover: #45A049;
  --warning-bg-color: #ff9800;
  --warning-bg-color-hover: #fb8c00;
  --danger-bg-color: #f44336;
  --danger-bg-color-hover: #e53935;
  --muted-bg-color: #757575;
  --muted-bg-color-hover: #9e9e9e;
  --panel-bg-color: #2a2a2a;
}
