@import './variables.css';

.our-guns {
  padding: 20px;
  margin-top: 120px;
  position: relative;
  background-color: var(--light-bg-color);
}

.our-guns h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--text-dark-color);
  text-align: center;
}

.used-guns-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.used-guns-button:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-2px);
}

.weapon-reviews-button {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 500;
}

.manufacturers-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.manufacturer-card {
  cursor: pointer;
}

.manufacturer-card.selected .gun-card {
  border: 2px solid var(--primary-color);
  transform: scale(1.1);
}

.gun-card {
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px var(--shadow-color);
  width: 150px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.gun-card img {
  width: 80px;
  height: 80px;
  object-fit: scale-down;
  margin-bottom: 10px;
}

.gun-card img.color-invert {
  filter: invert(1);
}

.gun-card p {
  margin: 0;
  font-size: 1.2rem;
  color: var(--text-dark-color);
}

.gun-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px var(--shadow-color);
}

.models-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 10px;
  background-color: var(--tray-bg-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
  justify-items: center;
}

.model-card {
  background-color: var(--model-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  width: 150px;
  box-shadow: 0 2px 4px var(--shadow-color);
  transition: transform 0.3s;
  cursor: pointer;
}

.model-card img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.model-card p {
  margin: 0;
  font-size: 1rem;
  color: var(--text-dark-color);
  font-weight: bold;
}

.model-card:hover {
  transform: scale(1.1);
}

.model-card.selected {
  border: 2px solid var(--primary-color);
  transform: scale(1.1);
}

.model-detail-card {
  display: flex;
  flex-direction: column;
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--shadow-color);
  padding: 20px;
  margin-top: 20px;
  max-width: 1200px;
  margin: 20px auto;
}

.model-detail-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.model-detail-image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-detail-image {
  max-width: 600px;
  object-fit: scale-down;
  height: 250px;
  background: white;
  border-radius: 10px;
  aspect-ratio: 1/1; /* Add this line to make the image square */
}

.model-detail-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.model-detail-info h3 {
  margin-bottom: 10px;
  font-size: 1.8rem;
  color: var(--text-dark-color);
}

.model-detail-info p {
  font-size: 1rem;
  color: var(--text-dark-color);
}

.model-edit-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--text-dark-color);
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 1rem;
  background-color: var(--input-bg-color);
  color: var(--text-dark-color);
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.video-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.remove-video-button,
.add-video-button,
.save-button,
.edit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-video-button:hover,
.add-video-button:hover,
.save-button:hover,
.edit-button:hover {
  background-color: var(--primary-color-hover);
}

.videos {
  margin-top: 20px;
}

.video iframe {
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .our-guns {
    padding: 10px;
    margin-top: 155px;
  }

  .our-guns h2 {
    font-size: 1.5rem;
    margin-top: 40px;
  }

  .used-guns-button,
  .weapon-reviews-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .manufacturers-row {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .gun-card {
    width: 100px;
    padding: 10px;
  }

  .gun-card img {
    width: 60px;
    height: 60px;
  }

  .gun-card p {
    font-size: 0.9rem;
  }

  .models-row {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }

  .model-card {
    width: 100px;
    padding: 10px;
  }

  .model-card img {
    height: 80px;
  }

  .model-card p {
    font-size: 0.9rem;
  }

  .model-detail-card {
    padding: 15px;
  }

  .model-detail-content {
    flex-direction: column;
  }

  .model-detail-image-section {
    margin-bottom: 15px;
  }

  .model-detail-info h3 {
    font-size: 1.5rem;
  }

  .model-detail-info p {
    font-size: 0.9rem;
  }
}
