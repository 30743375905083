@import './variables.css';

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #f1f1f1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #405885 #2a2a2a;
}

.management {
  margin-top: 110px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #1a1a1a;
  color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.control-panel {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.panel-item {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 18%;
  min-width: 150px; /* Ensure better spacing on small screens */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.panel-item h3 {
  margin: 0 0 10px 0;
  color: #f1f1f1;
  font-size: 1.2rem;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.actions button {
  background-color: #405885;
  color: var(--text-dark-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.actions button:hover {
  background-color: #21a1f1;
}

.subscribers-list {
  margin-bottom: 20px;
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.subscribers-list h3 {
  margin-bottom: 15px;
  color: #f1f1f1;
}

.subscribers-list table {
  width: 100%;
  border-collapse: collapse;
}

.subscribers-list th,
.subscribers-list td {
  padding: 10px;
  border: 1px solid #444;
}

.subscribers-list th {
  background-color: #333;
  color: #f1f1f1;
}

.subscribers-list td {
  background-color: #1a1a1a;
  color: #ddd;
}

.promote-event, .manage-pistols, .hours-updater {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px; /* Ensure some spacing at the bottom */
}

.promote-event h3, .manage-pistols h3, .hours-updater h3 {
  margin: 0 0 10px 0;
  color: #f1f1f1;
}

.promote-event input,
.promote-event textarea,
.manage-pistols input,
.manage-pistols textarea {
  width: 100%;
  padding: 10px;
  color: #f1f1f1;
  margin-bottom: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #1a1a1a;
}

.promote-event input::placeholder,
.promote-event textarea::placeholder,
.manage-pistols input::placeholder,
.manage-pistols textarea::placeholder {
  color: #888;
}

.promote-event button, .manage-pistols button {
  background-color: #405885;
  color: var(--text-dark-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.promote-event button:hover, .manage-pistols button:hover {
  background-color: #21a1f1;
}

/* Responsive styles */
@media (max-width: 768px) {
  .control-panel {
    flex-direction: column;
    align-items: center;
    width:98%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .panel-item {
    width: 90%;
    margin-bottom: 10px;
  }

  .actions {
    flex-direction: column;
    align-items: center;
  }

  .subscribers-list table th,
  .subscribers-list table td {
    padding: 8px;
  }

  .promote-event input,
  .promote-event textarea,
  .manage-pistols input,
  .manage-pistols textarea {
    padding: 8px;
  }
}
