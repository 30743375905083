.used-pistols-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: var(--light-bg-color);
    color: var(--text-dark-color);
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 4px 10px var(--shadow-color);
    max-width: 800px;
    margin-top: 100px;
  }
  
  .used-pistols-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color);
    font-size: 24px;
  }
  
  .used-pistols-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .used-pistols-table th,
  .used-pistols-table td {
    padding: 12px;
    border: 1px solid var(--border-color);
    text-align: left;
  }
  
  .used-pistols-table th {
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    font-weight: bold;
  }
  
  .used-pistols-table td {
    background-color: var(--light-bg-color);
    color: var(--text-dark-color);
  }
  
  
  @media (max-width: 600px) {
    .used-pistols-container {
      padding: 15px;
      margin-top: 150px;
    }
  
    .used-pistols-container h2 {
      font-size: 20px;
    }
  
    .used-pistols-table th,
    .used-pistols-table td {
      padding: 8px;
      font-size: 14px;
    }
  }
  