.gun-collection {
    width: 99%;
    margin: 150px auto 20px auto;
}

/* Adjust filter header for mobile */
.filter-header {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.filter-header input,
.filter-header select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    width: 100%; /* Full width on mobile */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.filter-header button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Full width on mobile */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.filter-header button:hover {
    background-color: var(--primary-color-hover);
}

.nav-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 5px;
}
    

.date-divider {
    border:1px solid #f2f2f2; /* Light grey background */
    font-weight: bold; /* Make the text bold */
    text-align: center; /* Center the text */
  }
/* Ensure the table is responsive */
.guns-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    background-color: var(--card-bg-color);
    color: var(--text-dark-color);
    text-align: center;
    overflow-x: auto; /* Enable horizontal scrolling */
}

.guns-table th,
.guns-table td {
    padding: 5px;
    border: 1px solid var(--border-color);
}

.guns-table th {
    background-color: var(--tab-bg-color);
    color: var(--text-dark-color);
}

.guns-table tbody tr:nth-of-type(even) {
    background-color: var(--light-bg-color);
}

.guns-table tbody tr:hover {
    background-color: var(--muted-bg-color-hover);
}

.button-disabled {
    background-color: var(--primary-color-hover);
    color:white;
}
  

/* Mobile adjustments */
@media (max-width: 768px) {
    .filter-header input,
    .filter-header select,
    .filter-header button {
        font-size: 14px; /* Slightly smaller font for smaller screens */
        padding: 8px; /* Reduce padding */
        width: 100%; /* Full width for better layout */
    }

    .guns-table {
        font-size: 14px; /* Reduce table font size */
    }

    .guns-table th,
    .guns-table td {
        padding: 4px; /* Reduce padding in table cells */
    }

    /* Ensure table can scroll horizontally */
    .guns-table {
        overflow-x: auto;
        display: block;
    }
}
