@import './variables.css';

.opening-hours-updater {
  background-color: var(--panel-bg-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

.opening-hours-updater h2 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1.1em;
  margin-bottom: 8px;
}

.form-group select,
.form-group input[type="text"],
.form-group input[type="time"] {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  color: var(--input-text-color);
  background-color: var(--input-bg-color);
  border: none;
  border-radius: 4px;
}

.form-group input[type="text"]:focus,
.form-group input[type="time"]:focus,
.form-group select:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

/* Specific button styling for OpeningHoursUpdater */
.ohu-button {
  background-color: var(--primary-color);
  color: var(--button-text-color);
  padding: 12px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.ohu-button:hover {
  background-color: var(--primary-color-hover);
}

.ohu-button:active {
  background-color: var(--primary-color-active);
}

.ohu-button:disabled {
  background-color: var(--button-disabled-bg-color);
  cursor: not-allowed;
}
