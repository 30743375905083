.ocr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .webcam {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .capture-button,
  .process-button {
    background-color: #4e84e9;
    color: var(--text-dark-color);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 10px 0;
  }
  
  .capture-button:hover,
  .process-button:hover {
    background-color: #3c6eb4;
  }
  
  .preview-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .preview-image {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .results-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .results-container p {
    font-size: 16px;
    color: #333;
  }
  
  .results-container ul {
    list-style: none;
    padding: 0;
  }
  
  .results-container li {
    font-size: 16px;
    margin: 5px 0;
  }
  