@import './variables.css';

/* Container for the form */
.gun-ammo-train-detail {
  background-color: var(--light-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 5px 15px var(--shadow-color);
  position: relative;
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
}

/* Form header */
.gun-ammo-train-detail h3 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--text-dark-color);
}

/* Form fields */
.gun-ammo-train-details-col {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.gun-ammo-train-details-col label {
  font-size: 16px;
  color: var(--text-dark-color);
}

.gun-ammo-train-details-col input,
.gun-ammo-train-details-col select {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  font-size: 1rem;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
}

/* Signature canvas */
.gun-ammo-train-sigCanvas {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
}

/* Buttons */
.gun-ammo-train-show-button {
  padding: 15px;
  font-size: 18px;
  color: var(--text-dark-color);
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: background-color 0.3s, transform 0.3s;
  margin: 10px 0;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.gun-ammo-train-show-button:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-2px);
}

/* Center buttons */
.gun-ammo-train-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Loading spinner */
.gun-ammo-train-loading-spinner-small {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin: 20px 0;
}

.gun-ammo-train-loading-spinner-small::after {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid var(--primary-color);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .gun-ammo-train-details-col {
    gap: 10px;
  }

  .gun-ammo-train-show-button {
    margin-bottom: 10px;
  }
}
