@import './variables.css';

/* General styling */
.new-license-content {
  font-family: 'Roboto', sans-serif;
  max-width: 900px;
  margin: 120px auto 40px auto;
  padding: 30px;
  border-radius: 15px;
  background: var(--tab-bg-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  border: 1px solid var(--border-color);
}

.new-license-content h2 {
  font-size: 28px;
  color: var(--primary-color);
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.new-license-content p {
  margin-bottom: 20px;
  color: var(--text-dark-color);
  line-height: 1.8;
  font-size: 18px;
}

.new-license-content ol {
  margin-left: 20px;
  margin-bottom: 25px;
  padding-left: 20px;
  border-left: 3px solid var(--primary-color);
}

.new-license-content ol li {
  margin-bottom: 15px;
  color: var(--text-dark-color);
  font-size: 17px;
  transition: color 0.3s ease;
}



.new-license-content a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: bold;
  background: var(--primary-color);
  transition: color 0.3s, text-shadow 0.3s;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 4px 8px;
}

.new-license-content a:hover {
  color: var(--text-dark-color);
  text-shadow: 2px 2px 5px var(--shadow-color);
}

/* Centered YouTube video */
.video-section {
  text-align: center;
  margin: 30px 0;
}

.video-section iframe {
  max-width: 100%;
  border-radius: 10px;
}

/* Specific styles for sections */
.new-license-content .contact-details,
.new-license-content .subscribe-youtube {
  margin-top: 25px;
  padding: 20px;
  background-color: var(--card-bg-color);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.new-license-content .subscribe-youtube a {
  display: inline-block;
  margin-top: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.new-license-content h3 {
  margin-top: 35px;
  color: var(--secondary-color);
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.new-license-content ol ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
}

.new-license-content ol ul li {
  margin-bottom: 10px;
  color: var(--text-dark-color);
  transition: transform 0.3s ease;
}



.youtube-banner {
  text-align: center;
  margin-top: 30px;
}

.youtube-banner a {
  display: inline-flex;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.youtube-banner a:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-3px);
}

.youtube-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.youtube-banner p {
  margin: 0;
  font-size: 18px;
}

/* Mobile Support */
@media (max-width: 768px) {
  .new-license-content {
    width: 95%;
    margin-top: 160px;
    padding: 20px;
    border-radius: 12px;
  }

  .new-license-content h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .new-license-content p,
  .new-license-content ol li {
    margin-bottom: 12px;
    margin-top: 10px;
    font-size: 16px;
  }

  .new-license-content ol {
    margin-left: 15px;
    margin-top: 10px;

    padding-left: 15px;
  }

  .new-license-content .contact-details,
  .new-license-content .subscribe-youtube {
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
  }

  .new-license-content .subscribe-youtube a {
    font-size: 16px;
  }
}
