/* src/styles/AboutUs.css */

@import './variables.css';

.about-us {
  text-align: center;
  padding: 20px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--shadow-color);
  margin: 20px auto;
  margin-top: 125px;
  width: 100%;
  max-width: 1200px;
  transition: background-color 0.3s, color 0.3s;
}

@media (max-width: 768px) {
  .about-us {
    width: 90%;
    margin: auto;
    margin-top: 160px;
  }
}
