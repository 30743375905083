/* Glowing button base styles */
.glow-button {
    --rotate: 0deg;
    position: relative;
    padding: 10px 20px;
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
  }
  
  .glow-button .glow-border {
    position: absolute;
    inset: 0;
    background: transparent;
    border-radius: 8px;
    z-index: -1;
  }
  
  .glow-button .glow-border::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: conic-gradient(from var(--rotate), transparent 0%, var(--glow-color) 15%, transparent 30%);
    border-radius: 8px;
    animation: gradient 5s ease-out infinite;
  }
  
  .glow-button .glow-border::after {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--primary-color);
    border-radius: 8px;
    z-index: 2;
  }
  
  .glow-button .text {
    position: relative;
    z-index: 3;
  }

  .taxt span:hover {
    background: black;
  }
  
  @keyframes gradient {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  