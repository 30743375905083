@import './variables.css';

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: var(--dark-bg-color);
}

.unsubscribe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--light-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 20px var(--shadow-color);
  margin: auto;
  max-width: 400px;
  width: 100%;
}

.unsubscribe-container h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: var(--text-dark-color);
}

.unsubscribe-container input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: var(--input-bg-color);
  color: var(--text-dark-color);
}

.unsubscribe-container button {
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s, transform 0.3s;
}

.unsubscribe-container button:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-3px);
}

.unsubscribe-container p {
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--text-dark-color);
}

/* Mobile Support */
@media (max-width: 600px) {
  .unsubscribe-container {
    padding: 15px;
    max-width: 90%;
  }

  .unsubscribe-container h2 {
    font-size: 1.5rem;
  }

  .unsubscribe-container input[type="email"] {
    padding: 8px;
    font-size: 0.9rem;
  }

  .unsubscribe-container button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .unsubscribe-container p {
    font-size: 0.8rem;
  }
}
