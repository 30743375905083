@import './variables.css';

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.popup {
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 20px var(--shadow-color);
  position: relative;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.close-icon:hover {
  opacity: 1;
}

.popup h2 {
  margin-top: 0;
  font-size: 1.8rem;
}

.popup button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.popup button:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-3px);
}

.popup input[type='email'],
.popup input[type='text'] {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--input-bg-color);
  color: var(--text-dark-color);
}

.popup input.invalid {
  border: 3px solid var(--error-color);
  background-color: rgba(255, 0, 0, 0.1);
  animation: flicker 0.5s 3;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Ensure responsiveness */
@media (max-width: 600px) {
  .popup {
    padding: 15px;
    width: 90%;
    max-width: 400px;
  }

  .popup h2 {
    font-size: 1.5rem;
  }

  .popup button {
    padding: 8px 16px;
    font-size: 1rem;
  }

  .popup input[type='email'],
  .popup input[type='text'] {
    padding: 8px;
    font-size: 1rem;
  }
}
