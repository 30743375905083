.table-container {
    width: 98%;
    margin: 60px auto 5px auto;
}
.table-overflow{
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */

}
.table-table {
    width: 98%;
    border-collapse: collapse;
    margin: 5px auto 5px auto;
    font-size: 12px; /* Reduced font size */
    background-color: var(--card-bg-color);
    color: var(--text-dark-color);
    text-align: center;
}

.table-table th,
.table-table td {
    padding: 3px ; /* Maintain smaller padding for better fit */
    border: 1px solid var(--border-color);
    font-size: 12px;

}

.table-table td {
    white-space: nowrap;white-space: nowrap;
}



.table-table th {
    background-color: var(--tab-bg-color);
    color: var(--text-dark-color);
}

.table-table tbody tr:nth-of-type(even) {
    background-color: var(--light-bg-color);

}

.table-table tbody tr:hover {
    background-color: #444;

}


.table-container {
    width: 100%;
    margin-bottom: 20px;
    
}

.table-container::-webkit-scrollbar {
    height: 8px; /* Adjust scrollbar height */
}

.table-container::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color); /* Customize scrollbar thumb */
    border-radius: 4px; /* Rounded scrollbar thumb */
}

.table-container::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color); /* Customize scrollbar track */
}


.clickable {
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
}

.clickable:hover {
    color: var(--primary-color-hover);
}

.controls {
    display: flex;
    flex-wrap: wrap; /* Allow controls to wrap on smaller screens */
    flex-direction: column;
    gap: 10px; /* Spacing between elements */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--panel-bg-color);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    justify-content: right;
    /* align-ite///ms: center; Vertically align all items */
}

.filters-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: var(--panel-bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.date-filters {
    display: flex;
    align-items: center;
    gap: 10px;
}

.date-input, .search-input, select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

.filter-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.filter-button:hover {
    background-color: #0056b3;
}

.actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.main-filters, .secondary-filters, .thired-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    background-color: var(--panel-bg-color);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    justify-content: right;
}

.controls h3 {
    margin-bottom: 0;
    color: var(--text-dark-color);
    font-size: 16px; /* Slightly smaller font for headers */
}

.table-row input[type="text"], .table-row input[type="date"], .table-row select, .table-row button {
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    flex: 1 1 150px; /* Flex-grow: 1, Flex-shrink: 1, Flex-basis: 150px */
}

.table-row button {
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.table-row button:hover {
    background-color: var(--primary-color-hover);
}

.load-more {
    display: flex;
}
.load-more-button{
    width:98%;
    font-size: 14px;
    padding: 6px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--primary-color);
    color: var(--text-dark-color);
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.stat-card {
  background-color: var(--card-bg-color);
  color: var(--text-dark-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1 1 200px;
  text-align: center;
}

.stat-card h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.stat-card p {
  margin: 5px 0;
  font-size: 14px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.stat-item {
  background-color: var(--card-bg-color);
  color: var(--text-dark-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-item h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.stat-item p {
  margin: 5px 0;
  font-size: 14px;
}

.date-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 14px;
}

.filter-grid, .date-filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-bottom: 20px;
}

select, input[type="text"], input[type="date"] {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

.filter-button, .csv-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-wrap: nowrap;
}

.filter-button:hover, .csv-button:hover {
    background-color: #0056b3;
}

.csv-button {
    padding: 8px 16px;
    background-color: #1D6F42; /* Excel green */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.csv-button:hover {
    background-color: #155a32;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #1976d2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .table-container {
        margin-top: 220px;
        
    }
    .controls{
        
    }
    .table-table {
        font-size: 11px; /* Further reduce font size on smaller screens */
        margin-top: 0;
    }

    .table-table th,
    .table-table td {
        padding: 4px 6px; /* Adjust padding for smaller screens */
    }
    
    .controls {
        flex-direction: column; /* Stack controls vertically on small screens */
    }
    
    .controls h3 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .table-row input[type="text"],
    .table-row select,
    .table-row button {
        flex: 1 1 100%; /* Full width on smaller screens */
    }
}

.custom-table-container {
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f8f9fa;
}

.custom-table th, .custom-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.custom-table th {
    background-color: #343a40;
    color: white;
    font-weight: bold;
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.custom-table tr:hover {
    background-color: #e9ecef;
    cursor: pointer;
}

.custom-table-actions {
    display: flex;
    justify-content: space-around;
}

.custom-table-actions button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-table-actions button:hover {
    background-color: #0056b3;
}
