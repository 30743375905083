@import './variables.css';

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.signature-instruction {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: center;
}

.sigCanvas {
  border: 2px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--signature-bg);
  cursor: crosshair;
  width: 100%;
  max-width: 500px;
  height: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease;
}

.sigCanvas:hover {
  border-color: var(--primary-color);
}

:root {
  --signature-color: #1976d2;
  --signature-bg: #ffffff;
}

[data-theme='dark'] {
  --signature-color: #90caf9;
  --signature-bg: #1e1e1e;
}

.error-text {
  color: var(--error-color);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .sigCanvas {
    height: 150px;
  }
  
  .signature-instruction {
    font-size: 0.85rem;
    padding: 0 1rem;
  }
}
