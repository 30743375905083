.transaction-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--light-bg-color);
    color: var(--text-dark-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1001;
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .transaction-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .transaction-popup-header h3 {
    margin: 0;
    font-size: 20px;
  }
  
  .transaction-popup-header button {
    background-color: var(--danger-bg-color);
    color: var(--text-dark-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .transaction-popup-header button:hover {
    background-color: var(--danger-bg-color-hover);
  }
  
  .transaction-list {
    display: flex;
    flex-direction: column;
  }
  
  .transaction-item {
    border: 1px solid var(--border-color);
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .transaction-summary {
    display: flex;
    justify-content: space-between;
  }
  
  .transaction-details {
    margin-top: 10px;
  }
  
  .transaction-detail-item {
    display: flex;
    justify-content: space-between;
  }
  
  .transaction-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .transaction-actions button {
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .transaction-actions button:hover {
    background-color: var(--primary-color-hover);
  }
  