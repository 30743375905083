/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.95); /* Slightly dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  .modal-content {
    max-width: 600px; /* Matches the registration form width */
    background-color: var(--card-bg-color); /* Match background color */
    color: var(--text-dark-color); /* Match text color */
    border-radius: 15px; /* Rounded corners */
    border: 1px solid var(--border-color); /* Matching border */
    box-shadow: 0 5px 15px var(--shadow-color); /* Shadow for depth */
    padding: 20px;
    text-align: center;
    font-family: 'Open Sans', sans-serif; /* Consistent font */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-content h2 {
    color: var(--primary-color); /* Title color */
    margin-bottom: 10px;
    margin-right: 20px; /* Margin for breathing space */
    margin-left: 20px; /* Margin for breathing space */
  }
  
  .modal-content p {
    font-size: 16px; /* Smaller font size */
    color: var(--text-dark-color); /* Consistent text color */
    margin-bottom: 20px;
  }
  
  .modal-buttons {
    margin-top: 20px;
  }
  
  .modal-button {
    padding: 12px;
    background-color: var(--primary-color); /* Match button color */
    color: var(--text-light-color); /* Button text color */
    border: none;
    border-radius: 10px; /* Rounded corners for buttons */
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .modal-button:hover {
    background-color: var(--primary-color-hover); /* Hover effect */
  }
  
  @media (max-width: 600px) {
    .modal-content {
      width: 95%; /* Responsive width */
      margin: 0 2.5%; /* Centered with margin */
      padding: 15px; /* Adjusted padding */
    }
  
    .modal-button {
      font-size: 14px; /* Smaller font size on mobile */
    }
  }
  