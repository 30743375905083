.ledger-container {
  width: 100%;
  padding: 20px;
  background-color: var(--panel-bg-color);
  color: var(--text-dark-color);
  margin-top: 100px;
}

.tab-header {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-header button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 220px;
}

.tab-header button.active {
  background-color: var(--primary-color-hover);
  border: 2px solid white;
}

.tab-header button:hover:not(.active) {
  background-color: var(--primary-color-hover);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .ledger-container {
    margin-top: 120px;
  }

  .tab-header {
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .tab-header button {
    margin-top: 25px;
    width: auto;

  }
}
