@import './variables.css';

.topbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  /* background-image: url('../images/iStock-2099730553.jpg');
  background-size: fill; */
  background-color: #2c2a2a;
  box-shadow: 0 2px 4px var(--shadow-color);
  color: var(--text-dark-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px var(--shadow-color);
  z-index: 1000;
  direction: rtl;
  box-sizing: border-box;
  /* background: linear-gradient(0deg, #333, #444); */
}

.topbar-title2 {
  font-size: 1.2rem;
  flex: 1;
  text-align: right;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.manager-button{
  width: 100%;
  padding: 3px 5px;
  margin-top: 10px;
  font-size: 1rem;
  color: var(--text-dark-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Add this line to make the text stay in a single row */
}

.home-button2 {
  margin: 0;
  background: none;
  height: fit-content;
  width: fit-content;
  border: none;
  color: #dddddd;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: right;
  transition: transform 0.3s, text-decoration 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.shooting-range-icon {
  width: 50px;
  height: 50px;
  filter: invert(1);
  animation: wiggle 2s infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.topbar-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.login-icon,
.accessibility-icon,
.opening-hours {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  filter: invert(1);
}

.user-icon {
  width: 40px;
  height: 40px;
  border: 1.5px solid white;
  border-radius: 50%;
  cursor: pointer;
}

.opening-hours:hover,
.accessibility-icon:hover,
.login-icon:hover {
  animation: wiggle 0.5s infinite;
  transform: scale(1.5);
}

.user-menu {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 40px;
  left: -5px;
  background-color:var(--card-bg-color);
  color: var(--text-dark-color);
  padding: 10px;
  border: 1px solid #666;
  border-radius: 4px;
  box-shadow: 0 2px 8px var(--shadow-color);
  display: none;
  flex-direction: column;
  min-width: 200px;
  width: 20%;
  font-size: 14px;
  z-index: 1001;
  text-align: center;
}

.user-menu:hover .dropdown {
  color: var(--text-light-color);
  display: flex;
}

.dropdown button {
  background: none;
  border: none;
  color: var(--text-dark-color);
  text-align: center;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.dropdown button:hover {
  background-color: #666;
  text-decoration: none;
}

body {
  margin-top: 80px; /* Adjust according to the topbar height */
}

@media (max-width: 768px) {
  .topbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .topbar-title2 {
    text-align: center;
    width: 100%;
    font-size: 1.0rem;
    margin-top: 3px;
  }

  .topbar-buttons {
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .manager-button{
    width: 30%;
    margin-top: -10px;
  }

  .login-icon,
  .user-icon,
  .opening-hours,
  .accessibility-icon {
    width: 30px;
    height: 30px;
  }

  .dropdown {
    width: 100%;
    top: 35px;
  }

  .shooting-range-icon {
    width: 30px;
    height: 30px;
  }
}
