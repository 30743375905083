@import './variables.css';

.accessibility-menu {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateX(-100%) translateY(-50%);
  transition: transform 0.3s ease-in-out;
  background-color: #444;
  color: white;
  padding: 20px;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 250px;
  text-align: center;
}

.accessibility-menu.open {
  transform: translateX(0) translateY(-50%);
}

.accessibility-menu h3 {
  margin-top: 0;
}

.accessibility-menu button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  border: 1px solid #61dafb;
}

.accessibility-menu button:hover {
  background-color: #555;
}

.close-button {
  position: absolute;
  top: 10px;
  right: -25px;
  background: #444;
  border: 1px solid #555;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessibility-menu div {
  margin-bottom: 10px;
}

/* Additional classes for dark mode, high contrast, and inverted colors */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.high-contrast {
  filter: contrast(2) !important;
}

.inverted-colors {
  filter: invert(1) !important;
}

/* Light mode overrides for buttons and background */
.dark-mode .accessibility-menu {
  background-color: #222;
  color: #ddd;
}

.dark-mode .accessibility-menu button {
  background-color: #444;
  color: #ddd;
  border: 1px solid #61dafb;
}

.dark-mode .accessibility-menu button:hover {
  background-color: #555;
}
