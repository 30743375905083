.manage-pistols-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: var(--light-bg-color);
    color: var(--text-dark-color);
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 4px 10px var(--shadow-color);
    max-width: 800px;
  }
  
  .manage-pistols-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color);
    font-size: 24px;
  }
  
  .manage-pistols-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .manage-pistols-table th,
  .manage-pistols-table td {
    padding: 12px;
    border: 1px solid var(--border-color);
    text-align: left;
  }
  
  .manage-pistols-table th {
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    font-weight: bold;
  }
  
  .manage-pistols-table td {
    background-color: var(--light-bg-color);
    color: var(--text-dark-color);
  }
  
  .manage-pistols-table input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
  }
  
  .manage-pistols-table button {
    padding: 8px 12px;
    background-color: var(--success-bg-color);
    color: var(--text-dark-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .manage-pistols-table button:hover {
    background-color: var(--success-bg-color-hover);
  }
  
  .add-pistol-form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .add-pistol-form h3 {
    color: var(--text-color);
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .add-pistol-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
  }
  
  .add-pistol-form button {
    padding: 10px 15px;
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-pistol-form button:hover {
    background-color: var(--primary-color-hover);
  }
  
  @media (max-width: 600px) {
    .manage-pistols-container {
      padding: 15px;
    }
  
    .manage-pistols-container h2 {
      font-size: 20px;
    }
  
    .manage-pistols-table th,
    .manage-pistols-table td {
      padding: 8px;
      font-size: 14px;
    }
  
    .add-pistol-form input {
      font-size: 14px;
    }
  }
  