/* src/styles/App.css */

@import './variables.css';

html, body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

button ,span, select {
  caret-color: transparent; 

}
/* :root{
  --primary-color: #007bff;
  --primary-color-hover: #0056b3;
  --dark-bg-color: #282c34;
  --text-dark-color: #fff;
  --text-light-color: #000;
  --border-color: #ccc;
  --input-bg-color: #fff;
  --input-text-color: #000;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --glow-color: #ffffff;
} */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--dark-bg-color);
  color: var(--text-dark-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}
