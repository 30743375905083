@import './variables.css';

/* TestResults Component Styles */
.test-results-container {
  width: 98%;
  padding: 20px;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  margin-top: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px var(--shadow-color);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto; /* Add horizontal scrolling */
}

/* General Table Styles */
.test-results-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: right;
  background-color: var(--card-bg-color);
  color: var(--text-dark-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 10px var(--shadow-color);
}

.test-results-table th,
.test-results-table td {
  padding: 12px 15px;
  border: 1px solid var(--border-color);
}

/* Table Header */
.test-results-table thead tr {
  background-color: var(--tab-bg-color);
  color: var(--text-dark-color);
}

/* Table Body */
.test-results-table tbody tr {
  border-bottom: 1px solid var(--border-color);
}

.test-results-table tbody tr:nth-of-type(even) {
  background-color: var(--light-bg-color);
}

.test-results-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--primary-color);
}

/* Heading Styles */
.test-results-heading {
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

/* Hover Effect */
.test-results-table tbody tr:hover {
  background-color: var(--muted-bg-color-hover);
}

/* Header for Date Selection */
.test-results-header {
  margin: 20px auto;
  margin-top: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.test-results-header label {
  margin-right: 10px;
  font-size: 16px;
  color: var(--text-dark-color);
}

#date-select,
#type-select {
  
  padding: 8px;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  box-shadow: 0 2px 4px var(--shadow-color);
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.refresh-button {
  padding: 8px 15px;
  font-size: 1rem;
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.refresh-button:hover {
  background-color: var(--primary-color-hover);
}





/* Responsive Table */
@media (max-width: 600px) {
  .test-results-container {
    overflow-x: auto; /* Ensure the container scrolls horizontally on small screens */
    
  }

  .test-results-table {
    width: 100%; /* Keep table width to ensure it fits within the scrolling container */
    font-size: 0.6em; /* Slightly reduce font size */
    margin: 1px auto; /* Adjust margin for better fit */
    
  }

  .test-results-table th,
  .test-results-table td {
    padding: 3px 4px; /* Adjust padding for better fit */
  }

  .test-results-table td {
    white-space: nowrap; /* Prevent text wrapping in table cells */
  }

  .test-results-header {
    flex-direction: column; /* Stack date and type selection on smaller screens */
    margin-top: 150px; /* Reduce top margin for better fit */
    align-items: center;
    justify-content: center;
  }

  #date-select,
  #type-select,
  .refresh-button {
    margin-top: 10px; /* Reduce margin for better fit */
    width: 95%; /* Make dropdown and button full width on small screens */
    margin-left: auto;
    margin-right: 5px;
  }
}
