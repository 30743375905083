.quiz-container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: var(--light-bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--shadow-color);
    border: 1px solid var(--border-color);
  }
  
  .quiz-header {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .quiz-header h3 {
    margin: 0;
    font-size: 24px;
    color: var(--text-dark-color);
  }
  .quiz-header p{
    margin: 0;
    margin-top: 5px;
    font-size: 16px;
    color: var(--text-dark-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 10px;
    background-color: var(--light-bg-color);

  }
  .quiz-header input[type="text"] {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    color: var(--text-dark-color);
    background-color: var(--light-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 10px;
  }

  .quiz-header input[type="number"] {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    color: var(--text-dark-color);
    background-color: var(--light-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 10px;
  }

  .quiz-header select {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    color: var(--text-dark-color);
    background-color: var(--light-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .quiz-header h4 {
    margin: 10px 0;
    font-size: 18px;
    color: var(--text-dark-color);
  }
  
  .quiz-questions {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-questions li {
    margin-bottom: 15px;
  }

  .quiz-questions li:last-child {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .quiz-questions label {
    font-size: 16px;
    color: var(--text-dark-color);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .quiz-questions input[type="radio"] {
    margin-right: 10px;
  }
  
  .quiz-submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: var(--text-dark-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
  }
  
  .quiz-submit-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  .quiz-result {
    text-align: center;
    font-size: 20px;
    color: var(--text-dark-color);
    margin: 0 auto;
    margin-top: 120px;
    padding: 20px;
    background-color: var(--light-bg-color);
    border: 1px solid var(--primary-color-hover);
    border-radius: 8px;
    width: 60%;
  }
  
  .quiz-result p {
    margin: 0;
    font-size: 18px;
    color: var(--text-dark-color);
  }
  
  .quiz-result .quiz-submit-button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color-hover);
  }
  
  .quiz-result .quiz-submit-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  /* Media queries for mobile responsiveness */
  @media (max-width: 768px) {
    .quiz-container {
      margin-top: 160px;
      padding: 15px;
      width:98%;
    margin-left: auto;
    margin-right: auto;
    }
  
    .quiz-header h3 {
      font-size: 20px;
    }
  
    .quiz-header h4 {
      font-size: 16px;
    }
  
    .quiz-questions label {
      font-size: 14px;
    }
  
    .quiz-submit-button {
      font-size: 14px;
    }
  
    .quiz-result {
      margin-top: 160px;
      font-size: 18px;
      width: 90%;
    }
  
    .quiz-result p {
      font-size: 16px;
    }
  }
  