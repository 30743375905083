@import './variables.css';

/* General footer styles */
.footer {
  display: flex;
  flex-direction: row;
  background-color: var(--dark-bg-color);
  color: white;
  padding: 20px;
  text-align: center;
  direction: rtl;
  justify-content: space-between;
  flex-wrap: wrap;
  background: linear-gradient(180deg, #2c2a2a, #444);
  /* background-image: url('../images/iStock-2099730553.jpg');
  background-size: fill;
  background-position: 50% 50%; */
  box-shadow: 0 -2px 4px var(--shadow-color);
}

.footer-address p {
  margin: 5px 0;
}

.footer-social {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer-social img {
  width: 40px;
  height: 40px;
  cursor: pointer;
  filter: invert(1);
  transition: transform 0.3s;
}

.footer-social img:hover {
  animation: wiggle 0.5s infinite;
  transform: scale(1.5);
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Popup styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.popup {
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  padding: 20px 40px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 20px var(--shadow-color);
  max-width: 500px;
  width: 90%;
  position: relative;
  animation: popup-anim 0.4s ease-out;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

@keyframes popup-anim {
  0% {
      transform: scale(0.8);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  background-color: var(--text-dark-color);
  border-radius: 50%;
}

.close-icon:hover {
  opacity: 1;
}

.popup h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.popup a {
  text-decoration: none;
  color: var(--text-dark-color);
}

.popup-buttons {
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.3s;
  display: block;
  width: 50%;
  
}

.popup-buttons:hover {
  background-color: var(--primary-hover-color);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-social {
    margin-top: 20px;
  }

  .footer-social img {
    width: 30px;
    height: 30px;
  }

  .popup {
    padding: 20px;
    margin: 10px;
    max-width: 85%;
  }

  .popup h3 {
    font-size: 1.4rem;
  }

  .popup button {
    padding: 10px 20px;
    font-size: 1rem;
    width: 50%;
  }

  .close-icon {
    width: 20px;
    height: 20px;
  }
}
