
/* General form styling */
.registration-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: var(--card-bg-color);
    color: var(--text-dark-color);
    border-radius: 15px;
    border: 1px solid var(--border-color);
    box-shadow: 0 5px 15px var(--shadow-color);
    font-family: 'Open Sans', sans-serif;
    margin-top: 100px;
  }
  
  .registration-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-color);
  }
  
  .registration-form p {
    text-align: center;
    font-size: 14px;
    color: var(--text-dark-color);
  }
  
  .info-text {
    font-size: 12px;
    color: var(--muted-bg-color);
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 14px;
    color: var(--text-dark-color);
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    font-size: 14px;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
  }
  
  .form-group input[type="file"] {
    padding: 5px;
    font-size: 14px;
    border-radius: 10px;
    
  }
  
  /* Signature pad styling */
 
 
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
 
  
  /* Confirm button styling */
  .confirm-button {
    width: 100%;
    padding: 12px;
    background-color: var(--primary-color);
    color: var(--text-light-color);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .confirm-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .registration-form {
      padding: 15px;
      width:98%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
    }
  
    .form-group label,
    .form-group input,
    .form-group select,
    .confirm-button {
      font-size: 14px;
    }

    
  
  }
  