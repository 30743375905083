@import './variables.css';

.weapon-reviews {
  text-align: center;
  padding: 20px;
  background-color: var(--dark-bg-color);
  color: var(--text-dark-color);
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--shadow-color);
  margin: 20px auto;
  margin-top: 95px;
  width: 80%;
  max-width: 1200px;
}

.videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.video {
  width: 300px;
  background-color: var(--bg-color);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.video iframe {
  border-radius: 10px;
}

.youtube-banner {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube-banner a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-dark-color);
  font-size: 1.2rem;
  padding: 15px 30px;
  background: #FF0000;
  border-radius: 30px;
  box-shadow: 0 4px 8px var(--shadow-color);
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.youtube-banner a:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px var(--shadow-color);
  background-color: #CC0000;
}

.youtube-icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  filter: invert(1);
  transition: transform 0.3s;
}

.youtube-banner a:hover .youtube-icon {
  animation: wiggle 0.5s infinite;
  transform: scale(1.2);
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.subscribe-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.subscribe-button a {
  background-color: #FF0000;
  color: var(--text-dark-color);
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.subscribe-button a:hover {
  background-color: #CC0000;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .weapon-reviews {
    width: 90%;
    margin-top: 140px;
  }

  .video {
    width: 100%;
  }

  .youtube-banner a {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .youtube-icon {
    width: 40px;
    height: 40px;
  }

  .subscribe-button a {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
