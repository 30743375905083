/* Importing variables from variables.css */
@import './variables.css';

/* Container */
.new-register-container {
  width: 90%;
  max-width: 800px;
  min-width: auto;
  margin: 50px auto;
  margin-top: 110px;
  padding: 20px;
  background-color: var(--card-bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px var(--shadow-color);
  color: var(--text-dark-color);
  overflow-x: hidden;
}

/* Section Headings */
.register-heading {
  text-align: center;
  font-size: 1.8rem;
  color: var(--white-text);
  margin-bottom: 20px;
}

.register-subheading {
  font-size: 1.5rem;
  color: var(--white-text);
  margin-bottom: 15px;
}

.nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

/* Buttons */
.pre-form-button, .form-button, .return-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 1rem;
  color: var(--text-dark-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pre-form-button:hover, .form-button:hover, .return-button:hover {
  background-color: var(--primary-color-hover);
}

/* Form Labels and Inputs */
.register-label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--text-dark-color);
}

.register-input, .register-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1rem;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
}

.register-checkbox {
  margin-right: 10px;
}

.register-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.license-validity-dropdowns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.license-validity-dropdowns label {
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--text-dark-color);
  gap: 10px;
}

.license-validity-dropdowns select {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1rem;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  max-width: 100%;
}

.register-date {
  width: auto; /* Adjust as needed for consistency */
}

.checkbox-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

/* File Input */
.file-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 1rem;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
}

/* Uploaded File Section */
.uploaded-file {
  margin-top: 10px;
  font-size: 1.0rem;
  margin-bottom: 10px;
}

.uploaded-file a {
  color: #4e84e9;
  text-decoration: none;
}

.uploaded-file a:hover {
  text-decoration: underline;
}

.upload-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.upload {
  height: 20px;
  background-color: var(--success-bg-color);
  text-align: center;
  line-height: 20px;
  color: white;
  border-radius: 5px;
}

.remove-file-button {
  padding: 8px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.upload-file {
  padding: 5px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.remove-file-button:hover {
  background-color: #d32f2f;
}

/* Warnings */
.holsterWarning {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  background-color: var(--tab-bg-color);
  padding: 10px;
  border-radius: 5px;
  font-size: 1.1rem;
  color: var(--text-dark-color);
  animation: pulse 1s infinite;
  text-align: center;
}

@keyframes pulse {
  0% {
    border: 1px solid red;
    color: red;
  }
  100% {
    border: 1px solid white;
  }
}

.holsterWarning img {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  animation: pulser 3s infinite ease-in-out;
}

@keyframes pulser {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.3;
  }
  75% {
    scale: 1.15;
  }
  100% {
    scale: 1.1;
  }
}

/* Sub-forms */
.sub-form-frame {
  margin: 20px 0;
}

/* Return Button */
.return-button {
  background-color: var(--primary-color);
  margin-bottom: 20px;
}



.clear-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: var(--primary-color-hover);
}

.email-request {
  font-size: 1.1rem;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: 600;
}

.chrome-tab {
  position: relative;
  top: -36px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 10px;
  background-color: var(--light-bg-color);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: var(--text-dark-color);
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: var(--primary-color);
}

.loading-dots span {
  animation: blink 1s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.delete-icon{
  
  cursor: pointer;
  width: 20px;
  height: 20px;
  
}

@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.sub-label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  border: 2px dashed var(--primary-color);
  border-radius: 5px;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropzone:hover {
  background-color: var(--primary-color-hover);
}

.tag-checkbox {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px 0;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.tag-checkbox.selected {
  background-color: var(--primary-color);
  color: white;
}

.tag-checkbox input {
  display: none;
}

.checkbox-label {
  display: inline-block;
  text-align: center;
  width: 100%;
  transition: transform 0.3s ease;
}

.tag-checkbox.selected .checkbox-label {
  transform: scale(1.5);
}



@media (max-width: 768px) {
  .new-register-container {
    margin-top: 230px;
    padding: 10px;
  }

  .register-heading {
    font-size: 1.5rem;
  }

  .form-button, .return-button {
    font-size: 0.9rem;
  }

  .uploaded-file a {
    font-size: 0.8rem;
  }

  .chrome-tab{
    top: -30px;
  }

  .email-request {
    font-size: 0.9rem;
    
  }
}
