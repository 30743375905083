.table-container {
    width: 98%;
    margin: 60px auto 5px auto;
}

.table-overflow {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.hits-actions{
    display: flex;
}

.table {
    width: 98%;
    border-collapse: collapse;
    margin: 5px auto;
    font-size: 12px;
    background-color: var(--card-bg-color);
    color: var(--text-dark-color);
    text-align: center;
}

.table th,
.table td {
    padding: 3px;
    border: 1px solid var(--border-color);
    font-size: 12px;
}

.table th {
    background-color: var(--tab-bg-color);
    color: var(--text-dark-color);
}

.table tbody tr:nth-of-type(even) {
    background-color: var(--light-bg-color);
}

.table tbody tr:hover {
    background-color: var(--light-bg-color);
}

.clickable {
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
}

.clickable:hover {
    color: var(--primary-color-hover);
}

/* New Controls Styling */
.filters, .search-bar {
    display: flex;
    gap: 10px;
    margin: 10px 15px;
    padding: 10px;
    background-color: var(--panel-bg-color);
    border-radius: 8px;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filters label {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-dark-color);
}

.filters input[type="month"], .search-bar input[type="text"] {
    padding: 8px 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-size: 12px;
    color: var(--text-dark-color);
    background-color: var(--input-bg-color);
    width: 100%;
    max-width: 200px;
}

.table-row input[type="text"],
.table-row select {
    padding: 6px 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-size: 12px;
    flex: 1 1 250px;
    max-width: 300px;
}

.table-row button {
    padding: 6px 15px;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--text-dark-color);
    transition: background-color 0.3s ease;
    flex: 1 1 100px;
}

.table-row button:hover {
    background-color: var(--primary-color-hover);
}

.load-more {
    display: flex;
    justify-content: center;
}

.load-more-button {
    width: 98%;
    font-size: 14px;
    padding: 6px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--text-dark-color);
}

.table-container::-webkit-scrollbar {
    height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

@media (max-width: 768px) {
    .table-container {
        margin-top: 220px;
    }

    .table {
        font-size: 11px;
        margin-top: 0;
    }

    .table th,
    .table td {
        padding: 4px 6px;
    }

    .controls {
        flex-direction: column;
    }

    .filters, .search-bar {
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }

    .table-row input[type="text"],
    .table-row select,
    .table-row button {
        flex: 1 1 100%;
    }
}
