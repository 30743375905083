/* src/components/LanguageSwitch.css */

.language-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin-top: 5px;
    border-bottom: 1px solid #ffffff;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .language-option {
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.5;
  }
  
  .language-option.active {
    transform: scale(1.3);
    opacity: 1;
  }
  
  .flag-icon {
    width: 40px;
    height: auto;
  }
  