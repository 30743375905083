/* src/styles/Content.css */

@import './variables.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  margin: 80px 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--text-color) var(--bg-color);
}

.content {
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: var(--bg-color);
  background-image: url('../images/iStock-1267157670.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  color: var(--text-color);
  min-height: calc(100vh - 200px);
  font-family: 'Roboto', sans-serif;
  animation: sun 120s linear infinite;
}
@keyframes sun {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}

.catalog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.catalog-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--shadow-color);
  transition: transform 0.3s, box-shadow 0.3s;
}

.catalog-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s;
}

.catalog-item:hover img {
  transform: scale(1.1);
}

.catalog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px var(--shadow-color);
}

.catalog-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  transition: background-color 0.3s;
}

.catalog-text h2 {
  margin: 0;
  font-size: 1.5rem;
}

.catalog-text p {
  margin: 10px 0 0;
  font-size: 1rem;
}

.catalog-item:hover .catalog-text {
  background-color: rgba(0, 0, 0, 0.9);
}

.content-panel {
  width: 100%;
  background-color: var(--bg-color);
  overflow: hidden;
  transition: transform 0.5s;
  transform: translateY(100%);
  box-shadow: 0 2px 8px var(--shadow-color);
}

.content-panel.active {
  transform: translateY(0);
}

.panel {
  padding: 20px;
  background-color: var(--bg-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
  color: var(--text-color);
  opacity: 0;
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .content {
    margin-top: 120px;
    width:98%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .catalog-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .catalog-item img {
    height: 200px;
  }

  .catalog-text h2 {
    font-size: 1.3rem;
  }

  .catalog-text p {
    font-size: 0.9rem;
  }
}
