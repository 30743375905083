/* Form Popup Container */
.form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--light-bg-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px var(--shadow-color);
    z-index: 1000;
    width: 100%;
    max-width: 800px;
    overflow-y: auto;
    max-height: 90vh;
    margin: 150px 0ssss;
  }
  
  /* Form Container with Grid Layout */
  .form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  /* Full Width Elements */
  .form-container h2 {
    grid-column: span 2;
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-dark-color);
  }
  
  .form-container .full-width {
    grid-column: span 2;
  }
  
  /* Labels and Inputs */
  .form-container label {
    display: flex;
    flex-direction: column;
    color: var(--text-dark-color);
    font-size: 14px;
  }
  
  .form-container input,
  .form-container select,
  .form-container textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    font-size: 14px;
    margin-top: 5px;
  }
  
  /* Checkbox Styling */
  .form-container .checkbox-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .form-container .checkbox-group input {
    width: auto;
    margin-top: 0;
  }
  
  /* Buttons */
  .form-container .button-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    grid-column: span 2;
    margin-top: 10px;
  }
  
  .form-container button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 5px;
    color: var(--text-light-color);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-container .save-button {
    background-color: var(--primary-color);
  }
  
  .form-container .save-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  .form-container .minimize-button {
    background-color: var(--danger-bg-color);
    top:10px;
    right:10px;
    padding: 10px;
    position: absolute;

  }
  
  .form-container .minimize-button:hover {
    background-color: var(--danger-bg-color-hover);
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .form-container {
      grid-template-columns: 1fr;
    }
  }
  