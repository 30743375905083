.progress-container {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    height: 10px;
    background-color: var(--border-color);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.3s ease;
  }
  
  .progress-labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -25px;
    width: 100%;
    font-size: 0.8rem;
  }
  
  .progress-labels span {
    color: var(--border-color);
    transition: color 0.3s ease;
  }
  
  .progress-labels span.active {
    color: var(--primary-color);
    font-weight: bold;
  }