@import './variables.css';

.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.welcome-popup {
  background-color: var(--light-bg-color);
  color: var(--text-dark-color);
  padding: 20px 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 20px var(--shadow-color);
  max-width: 500px;
  width: 100%;
  position: relative;
  animation: welcome-popup-anim 0.4s ease-out;
  font-family: 'Roboto', sans-serif;
}

@keyframes welcome-popup-anim {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.welcome-title {
  margin-top: 0;
  font-size: 1.8rem;
  color: var(--text-dark-color);
}

.welcome-subtitle {
  margin-top: 10px;
  font-size: 1.6rem;
  color: var(--text-dark-color);
}

.welcome-training-hours {
  border: 1px solid var(--border-color);
  background: var(--card-bg-color);
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
}

.welcome-training-hours h3 {
  margin-top: 0;
  font-size: 1.3rem;
  color: var(--text-dark-color);
}

.welcome-training-hours p {
  margin: 10px 0;
  font-size: 1rem;
  color: var(--text-dark-color);
}

.welcome-nav-button {
  background-color: var(--primary-color);
  color: var(--text-dark-color);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.3s;
}

.welcome-nav-button:hover {
  background-color: #3a6fcf;
  transform: translateY(-3px);
}

.welcome-close-icon {
  position: absolute;
  top: 5px; /* Positioning outside the popup for better visibility */
  right: 5px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
  z-index: 1002;
  filter: invert(1);
}

.welcome-close-icon:hover {
  opacity: 1;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-container p {
  margin-top: 20px;
  color: var(--text-dark-color);
}

/* Loading spinner styles */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .welcome-popup {
    padding: 15px;
    margin: 10px;
    max-width: 90%;
  }

  .welcome-title {
    font-size: 1.6rem;
  }

  .welcome-subtitle {
    font-size: 1.4rem;
  }

  .welcome-training-hours h3 {
    font-size: 1.2rem;
  }

  .welcome-training-hours p {
    font-size: 0.9rem;
  }

  .welcome-nav-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .welcome-close-icon {
    width: 36px;
    height: 36px;
    filter:invert(1);
  }
}
